import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import classnames from "classnames"
import logo from './../../../../assets/img/logo/logo-white.png'
import logo_innovate from './../../../../assets/img/logo/logo-innovate.png'

class SidebarHeader extends Component {
  render() {
    const hostName = window.location.hostname
    let {
      menuShadow
    } = this.props
    return (
      <div className="navbar-header" style={{ height: '100px' }}>
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/home" className="navbar-brand">
              {
                hostName == "crm.ticinnovation.org" ?
                <img src={logo_innovate} alt={logo} style={{ width: '140px', marginLeft: '30px', marginTop: '-15px' }} />
                :
                <img src={logo} alt={logo} style={{ width: '140px', marginLeft: '30px', marginTop: '-15px' }} />
              }
            </NavLink>
          </li>
        </ul>
        <div className={classnames("shadow-bottom", { "d-none": menuShadow === false })} />
      </div>
    )
  }
}

export default SidebarHeader
